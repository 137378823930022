import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './modules/user'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user,
  },
  getters,
  state: {
    
  },
  mutations: {
    reset(state, payload) {
        state.keepList = new Set([payload])
    },
    clearAlive(state, payload) {
        state.keepList = new Set()
    },
    addAlive(state, payload) {
        state.keepList.add(payload)
    },
	removeAlive(state, payload) {
		state.keepList.delete(payload)
	}
  }
})

export default store
