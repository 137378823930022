import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import VXETable from 'vxe-table'



import store from './store'
import router from './router'
import axios from 'axios'
import api from './http/api'
import {debounce} from "./utils/common";


import echarts from 'echarts'
import  particles from "particles.js"
import 'element-ui/lib/theme-chalk/index.css';
import 'vxe-table/lib/style.css'
import '@/styles/common.scss'
// import CKEditor from '@ckeditor/ckeditor5-vue2';
// import CKEditor from 'ckeditor5-custom-build/build/ckeditor';
// import CKEditor from 'ckeditor5-custom-build/build/ckeditor'
const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
	constructor(callback) {
		callback = debounce(callback, 16);
		super(callback);
	}
};
// Vue.use(CKEditor)
Vue.use(particles)
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VXETable)
Vue.prototype.$axios = axios
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
})
