import Cookies from 'js-cookie'

const TokenKey = 'x-wb-admin-token'
const MenuKey = 'x-wb-admin-Menu'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  const expires = new Date(new Date(new Date().getTime() + 24 * 60 * 60 * 1000).setHours(0, 0, 0, 0))
  return Cookies.set(TokenKey, token, { expires: expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
export function getMenu() {
  return Cookies.get(MenuKey)
}

export function setMenu(menu=[]) {
  return Cookies.set(MenuKey, menu)
}

export function removeMenu() {
  return Cookies.remove(MenuKey)
}
