import Vue from "vue";
import Router from "vue-router";
// import store from '../store/index'

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/login",
    component: () =>
        import ("@/views/login/login"),
    meta: {
        title: "Login"
    },
  },
  {
    path: "/makeSheetCard",
    component: () =>
        import ("@/views/makeSheetCard/index"),
    meta: {
        title: "答题卡制作"
    }
  },
  {
    path: "/",
    redirect:"/userInfo",
    component: () =>
        import ("@/views/layout/index"),
    meta: {
        title: "Login"
    },
  },
  {
    path: "/userInfo",
    redirect:"/userInfo/personInfo",
    component: () =>
        import ("@/views/userInfo/index"),
    meta: {
        title: "个人中心"
    },
    children:[
        {
            path: "personInfo",
            component: () =>
                import ("@/views/userInfo/personInfo"),
            meta: {
                title: "个人信息"
            }, 
        },
        {
            path: "schoolGradeSubject",
            component: () =>
                import ("@/views/userInfo/schoolGradeSubject"),
            meta: {
                title: "校年级科目"
            }, 
        },
        {
            path: "schoolGradeTerm",
            component: () =>
                import ("@/views/userInfo/schoolGradeTerm"),
            meta: {
                title: "校学年学期"
            }, 
        },
        {
            path: "schoolClassStudent",
            component: () =>
                import ("@/views/userInfo/schoolClassStudent"),
            meta: {
                title: "班级学生名单"
            }, 
        },
        {
            path: "schoolTeachersList",
            component: () =>
                import ("@/views/userInfo/schoolTeachersList"),
            meta: {
                title: "班级学生名单"
            }, 
        },
        {
            path: "schoolManaPerson",
            component: () =>
                import ("@/views/userInfo/schoolManaPerson"),
            meta: {
                title: "班级学生名单"
            }, 
        },
        
    ]
  },
  {
    path: "/examMarking",
    redirect:"/examMarking/markingTasks",
    component: () =>
        import ("@/views/examMarking/index"),
    hidden: true,
    meta: {
        title: "考试阅卷"
    },
    children: [
        {
            path: "markingTasks",
            component: () =>
                import ("@/views/examMarking/markingTasks"),
            meta: {
                title: "我的阅卷任务"
            }, 
        },
    ]
  },
  {
    path: "/analysisReport",
    component: () =>
        import ("@/views/layout/index"),
    hidden: true,
    meta: {
        title: "分析报告"
    },
  },
  {
    path: "/knowTopic",
    component: () =>
        import ("@/views/layout/index"),
    hidden: true,
    meta: {
        title: "校本题库"
    },
  },
  {
    path: "/makeSheetCard",
    component: () =>
        import ("@/views/makeSheetCard/index"),
    hidden: true,
    meta: {
        title: "制卡"
    },
  },
  
]

const createRouter = () =>
    new Router({
        mode:"hash",
        routes: constantRoutes,
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return {
                    x: 0,
                    y: 0
                }
            }
        },
    });

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}
// router.beforeEach((to, from, next) => {
//     if (to.meta.keep === from.meta.keep) { // 进入detail页面 缓存当前list页面
//         store.commit('addAlive', from.name)
//     } else {
//         store.commit('clearAlive')
//     }
//     next()
// })


export default router;