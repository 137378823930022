import {
  getToken,
  removeToken,
  removeMenu
} from '@/utils/auth'
import {
  resetRouter
} from '@/router'
import {
  login
} from '../../http/api/user'

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  userName: '',
  roleId: "",
  buttons: [],
}

const mutations = {

  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_userName: (state, userName) => {
    state.userName = userName
  },
  // roleId orderMgmt加权限
  SET_roleId: (state, roleId) => {
    state.roleId = roleId
  },
  SET_BUTTONS: (state, buttons) => {
    state.buttons = buttons
  }
}

const actions = {
  // roleId
  SET_roleId: ({
    commit
  }, roleId) => {
    commit('SET_roleId', roleId)
  },

  SET_TOKEN: ({
    commit
  }, token) => {
    commit('SET_TOKEN', token)
  },
  SET_userName: ({
    commit
  }, userName) => {
    commit('SET_userName', userName)
    localStorage.setItem('userName', userName)
  },
  SET_BUTTONS: ({
    commit
  }, buttons) => {
    const buttonAuthList = []
    buttons.forEach(button => {
      if (button.urlType == "2") {
        buttonAuthList.push(button.url)
      }
    })
    console.log("buttonAuthList==", buttonAuthList)
    commit('SET_BUTTONS', buttonAuthList)
  },
  // user login
  login({
    commit
  }, userInfo) {
    console.log('action')
    const {
      username,
      password
    } = userInfo
    return new Promise((resolve, reject) => {
      console.log('action login')
      login({
        loginId: username.trim(),
        password: password
      }).then(response => {
        console.log('action login response')
        const {
          data
        } = response
        console.log('data.accessToken' + data.accessToken)
        commit('SET_TOKEN', data.accessToken)
        // setToken(data.accessToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_TOKEN', '')
      removeToken()
      resetRouter()
      resolve()
      // remove menu
      removeMenu()
      commit('SET_menuList')
    })
  },

  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
